import { Get, Post } from "../assets/request";

const baseUrl = "/ydyc/gpt/app"

export default {
  // 登录
  login: (params) => {
    return Post(baseUrl +'/client/login', params);
  },
  // 注册
  register: (params) => {
    return Post(baseUrl +'/client/register', params);
  },
  // 新建对话
  createWindow:(params) => {
    return Post(baseUrl +'/chat/window', params,"form");
  },
  // 删除对话框
  deleteWindow:(params) => {
    return Post(baseUrl +'/chat/window/del', params,"form");
  },
  // 清空上下文
  clearChat:(params) => {
    return Post(baseUrl +'/chat/clear?clientid='+params.clientid + "&chatid=" + params.chatid);
  },
  getOneOfTheChat:(params) => {
    return Get(baseUrl +'/chat/load?clientid='+params.clientid + "&chatid=" + params.chatid + "&page=" + params.page);
  },
  // 获取聊天窗口内容
  updatedChatWindows (params) {
    return Get(baseUrl +'/chat/window?clientid='+params.clientid);
  },
  // 退出登录
  logOut:(params) => {
    return Post(baseUrl +'/client/logout',params);
  },
  // 获取输入框提示语
  getJSONData:(params) => {
    return Get('https://aigc.funlife.today/prompt/prompt.json',params);
    // return Get('/prompt/prompt.json',params);
  },
  // 消费记录
  getAccountConsume (params) {
    return Get(baseUrl +'/account/consume', params) ;
  },
  // 充值记录
  getAccountRecharge (params) {
    return Get(baseUrl +'/account/recharge', params) ;
  },
  //  账户余额
  getAccountPoints (params) {
    return Get(baseUrl +'/account/points', params) ;
  },
  // 关键词列表
  getKeywordsList (params) {
    return Get(baseUrl +'/keywords/list', params) ;
  },
  // 保存关键词
  saveKeywords:(params) => {
    return Post(baseUrl +'/keywords/save',params);
  },
  // 修改关键词
  updateKeywords:(params) => {
    return Post(baseUrl +'/keywords/update',params);
  },
  // 删除关键词
  deleteKeywords:(params) => {
    return Post(baseUrl +'/keywords/delete',params);
  },
  // 网址上传
  uploadUrl:(params) => {
    return Post('/py/api/summarize_web', params);
  }
}
