import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: 'repair',
    name: 'repair',
    component: () => import(/* webpackChunkName: "about" */ '../views/repair.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    meta:{
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },
  {
    path: '/modules',
    name: 'modules',
    meta:{
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/modules.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  // base: '/dist/',
  routes
})


router.beforeEach((to, from, next) => {
  // 设置路由守卫，在进页面之前，判断有登录信息，才进入页面
  // 7天过期时间
  // let preDate = parseInt(localStorage.getItem('preDate')) || new Date().getTime();
  // let expires = preDate + 604800000;
  // if (new Date().getTime() > expires) {
  //   localStorage.removeItem('sessionid');
  //   localStorage.removeItem('chatUser');
  //   localStorage.removeItem('preDate');
  // } else {
  //   localStorage.setItem('preDate', preDate);
  // }
  const chatUser = sessionStorage.getItem("chatUser") || {};
  if (!chatUser) {
    next('/');
  } else {
    if (chatUser) {
      next();
    } else {
      next('/');
    }
  }
})
export default router
