import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Api from "./assets/api";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "github-markdown-css/github-markdown.css";
import "github-markdown-css/github-markdown.css";
import hljs from "highlight.js";

Vue.config.productionTip = false;
Vue.prototype.$axios = Api;

Vue.use(ElementUI);

import { message } from '@/assets/resetMessage';
Vue.prototype.$message = message

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.directive('highlight', el => {
  let blocks = el.querySelectorAll('pre code');
  Array.prototype.forEach.call(blocks, hljs.highlightBlock);
});
// 防抖
const throttle = {
  bind: (el, binding) => {
    let throttleTime = binding.value; // 防抖时间
    if (!throttleTime) {
      // 用户若不设置防抖时间，则默认1s
      throttleTime = 1000;
    }
    let timer;
    let disable = false;
    el.addEventListener(
      "click",
      event => {
        if (timer) {
          clearTimeout(timer);
        }
        if (!disable) {
          // 第一次执行(一点击触发当前事件)
          disable = true;
        } else {
          event && event.stopImmediatePropagation();
        }
        timer = setTimeout(() => {
          timer = null;
          disable = false;
        }, throttleTime);
      },
      true
    );
  }
};

Vue.directive("throttle", throttle);

