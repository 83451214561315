import axios from "axios";
import { Message } from "element-ui";
import router from "../router/index";

const instance = axios.create({
  // baseURL: process.env.NODE_ENV === "production" ? "" : "/api",
  baseURL: process.env.NODE_ENV === "production" ? "" : "/",
  timeout: 10000,
  withCredentials: true
});

// 请求拦截
instance.interceptors.request.use(config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  response => {
    console.log(response, "response");
    const resCode = response.status;
    const dataCode = response.data.code;
    if (resCode === 200) {
      switch (dataCode) {
        case -1:
          Message.error({
            message: '未知错误',
            duration: 5000
          });
          break;
        case 1000:
          Message.error({
            message: '用户还未注册',
            duration: 5000
          });
          break;
        case 1001:
          Message.error({
            message: '密码错误请重新输入',
            duration: 5000
          });
          break;
        case 1002:
          Message.error({
            message: '该手机号已经注册过',
            duration: 5000
          });
          break;
        case 1003:
          Message.error({
            message: '邀请码错误',
            duration: 5000
          });
          break;
        case 1004:
          Message.error({
            message: '手机号格式错误',
            duration: 5000
          });
          break;
        case 1005:
          Message.error({
            message: '密码格式错误',
            duration: 5000
          });
          break;
        case 1006:
          Message.error({
            message: '创建对话窗口失败，标题没有有效字符',
            duration: 5000
          });
          break;
        case 1007:
          Message.error({
            message: '用户登录状态失效',
            duration: 5000
          });
          sessionStorage.clear();
          // localStorage.removeItem('sessionid');
          // localStorage.removeItem('chatUser');
          router.replace({
            path: "/",
          });
          break;
        case 1008:
          Message.error({
            message: '创建对话窗口失败，传递的用户ID非法',
            duration: 5000
          });
          break;
        case 1009:
          Message.error({
            message: '更新对话窗口失败，传递的对话窗口ID非法',
            duration: 5000
          });
          break;
        case 1010:
          Message.error({
            message: '当前账户已在其他客户端登录',
            duration: 5000
          });
          sessionStorage.clear();
          // localStorage.removeItem('sessionid');
          // localStorage.removeItem('chatUser');
          router.replace({
            path: "/",
          });
          break;
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
    // return response;
  },
  error => {
    const resCode = error.response.status;
    switch (resCode) {
      case 401:
        Message.error(error.response.data.message);
        break;
      case 404:
        Message.error("网络请求不存在");
        break;
      case 500:
        Message.error("服务器连接错误");
        break;
      // 其他状态码错误提示
      default:
        Message.error(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

/*
 *封装get方法
 *@param{String} url [请求地址]
 *@param{Object} params 请求参数
 */
export function Get(url, params) {
  return new Promise((resolve, reject) => {
    instance.get(url, {
        params: params
      }).then(res => {
        resolve(res.data);
      }).catch(error => {
        reject(error.data);
      });
  });
}

/**
 *封装post方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Post(url, params, type) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Content-Type": type == "form" ? "application/x-www-form-urlencoded" : "application/json;charset=utf-8",
          "withCredentials": true
        },
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
}

/**
 *封装put方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Put(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
}

/**
 *封装patch方法
 *@param{String} url 请求地址
 *@param{Object} params 请求参数
 */
export function Patch(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
}

/**
 *封装delete方法
 *@param{String} url [请求地址]
 *@param{Object} params [请求参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params: params,
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error.data);
      });
  });
}
